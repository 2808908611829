<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:body>
            <div class="row align-items-center justify-content-center">
              <div class="col-md-5 iq-item-product-left ">
                <div class="h-56 d-flex align-items-center justify-content-center mb-4">
                  <img :src="product.img || require('../../assets/images/product-placeholder.png')" style="width:100%" class="grid-view-img px-4 w-200" />
                </div>
              </div>
              <div class="col-md-7 iq-item-product-right">
                <div class="product-additional-details">
                  <h4 class="productpage_title">{{ product.name }}</h4>
                  <div class="product-price">
                    <div class="regular-price" v-if="product.priceDiscount !== 0">
                      <b> {{ product.priceDiscount }} {{ product.currency }}</b>
                      <span class="offer-price ml-2">$ {{ product.price }} {{ product.currency }}</span>
                    </div>
                    <div class="regular-price" v-if="product.priceDiscount === 0">
                      <b> {{ product.price }} {{ product.currency }}</b>
                    </div>
                  </div>
                  <div class="stock">
                    <p>Stoc: <span class="text-success">In stoc</span></p>
                  </div>
                  <div class="additional-product-action d-flex align-items-center">
                    <div class="product-action">
                      <div class="add-to-cart">
                        <b-button v-if="checkCart(selectedProductState)" @click="$router.push({name: 'app.e-commerce.cart'})" variant=" iq-bg-primary"><i class="fas fa-eye"/> View Cart</b-button>
                        <b-button variant="primary" @click="addCart(selectedProductState)" v-else><i class="fas fa-cart-plus"/> Add to Cart </b-button>
                      </div>
                      <div class="wishlist">
                        <b-button variant=" iq-bg-primary ml-2" v-if="wishCart(selectedProductState)" @click="addToWishlist(selectedProductState)"><i class="fas fa-heart" />Wishlist</b-button>
                        <b-button variant="primary ml-2" @click="addWishlist(selectedProductState)" v-else><i class="far fa-heart" /> Wishlist</b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 product-description-details mt-4">
                <div class="description-tabs">
                  <b-tabs content-class="mt-3" align="center">
                    <b-tab title="Descriere" active>
                      <div v-html="product.description" style="width:100%; overflow: hidden"></div>
                    </b-tab>
                    <b-tab title="Specificatii" v-if="productTags.length > 0" class="productTags">
                      <div v-for="(item, key) in productTags" :key="key" class="row">
                        <div class="col-sm-6">{{ item.name }}</div>
                        <div class="col-sm-6">{{ item.value }}</div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
          </template>
        </iq-card>
        <b-row>
          <b-col class="related-product">
            <div class="related-heading text-center mb-3">
              <h2>Produse din aceeasi categorie</h2>
            </div>
            <div class="related-product-block">
              <Swiper :options="options" id="related-slider" class="product_list row p-0">
                <template v-slot:nav>
                  <SwiperNav />
                </template>
                <SwiperSlide class="product_item p-2"  v-for="item in related" :key="item.value">
                  <iq-card>
                    <ProductGrid :item="item" @cart="addToCart" :selectedCart="checkCart(item)" @wishlist="addToWishlist" :selectedWishCart="wishCart(item)"/>
                  </iq-card>
                </SwiperSlide>
              </Swiper>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
import ProductGrid from '@/views/Customer/ProductGrid'
import Swiper from '../../components/core/slider/Swiper'
import SwiperNav from '../../components/core/slider/SwiperNav'
import SwiperSlide from '../../components/core/slider/SwiperSlide'
import Axios from 'axios'
import { API } from '@/config/api.config'

export default {
  name: 'Detail',
  components: {
    ProductGrid,
    Swiper,
    SwiperSlide,
    SwiperNav
  },
  mounted () {
    core.index()
    this.getRelatedProducts()
    this.getProduct()
  },
  data () {
    return {
      related: [{ name: 'A', description: '', brand: 'Apple', objectID: '6443034', _highlightResult: { name: { value: 'Apple - MacBook Air® (Latest Model) - 13.3&quot; Display - Intel Core i5 - 8GB Memory - 128GB Flash Storage - Silver', matchLevel: 'none', matchedWords: [] }, description: { value: 'MacBook Air features up to 8GB of memory, a fifth-generation Intel Core processor, Thunderbolt 2, great built-in apps, and all-day battery life.1 Its thin, light, and durable enough to take everywhere you go-and powerful enough to do everything once you get there, better.', matchLevel: 'none', matchedWords: [] }, brand: { value: 'Apple', matchLevel: 'none', matchedWords: [] }, categories: [{ value: 'Computers &amp; Tablets', matchLevel: 'none', matchedWords: [] }, { value: 'Laptops', matchLevel: 'none', matchedWords: [] }, { value: 'All Laptops', matchLevel: 'none', matchedWords: [] }, { value: 'MacBooks', matchLevel: 'none', matchedWords: [] }], type: { value: 'Apple', matchLevel: 'none', matchedWords: [] } }, __position: 5 }],
      options: {
        loop: true,
        spaceBetween: 15,
        slidesPerView: 4,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      productToken: this.$route.params.product || '',
      product: [],
      productShort: '',
      productTags: []
    }
  },
  methods: {
    getRelatedProducts () {
      const postData = {
        product: this.productToken
      }
      Axios.post(API.API_CUSTOMER_GET_PRODUCT_RELATED, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.related = response.data.product
          } else {
            this.$router.back(-1)
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getProduct () {
      const postData = {
        product: this.productToken
      }
      Axios.post(API.API_CUSTOMER_GET_PRODUCT_INFO, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.product = response.data.product
            this.productShort = this.product.description.substring(0, 100)
            this.productTags = JSON.parse(this.product.tags)
          } else {
            this.$router.back(-1)
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    addCart (item) {
    },
    addWishlist (item) {
    },
    checkCart (item) {
    },
    wishCart (item) {
    }
  }
}
</script>
<style>
.productTags > div:nth-of-type(odd){
  background: #eff9fe;
}
</style>
