<template>
  <div>
    <a href="javascript:void(0)" :title="item.name">
      <div class="h-56 d-flex align-items-center justify-content-center bg-white iq-border-radius-15">
        <a href="javascript:void(0)"><img :src="item.img" :alt="item.name" class="grid-view-img px-4 pt-4 w-100" /></a>
      </div>
    </a>
    <b-card-body>
      <div class="text-justify">
        <a href="javascript:void(0)" :title="item.name">{{ item.name.substring(0,25) }}<span v-if="item.name.length >= 45">...</span></a>
      </div>
      <div class="iq-product-action my-2">
        <b-button variant="primary iq-waves-effect text-uppercase" v-if="!selectedCart" size="sm"><i class="fas fa-cart-plus mr-0"/></b-button>
        <router-link :to="{ name: 'app.e-commerce.cart'}" v-else class="btn iq-bg-primary iq-waves-effect text-uppercase btn-sm">
          <i class="fas fa-eye mr-0"/>
        </router-link>
        <b-button variant=" iq-bg-primary ml-2" size="sm" v-if="selectedWishCart"><i class="fas fa-heart mr-0" /></b-button>
        <b-button variant="primary ml-2" size="sm" @click="addToWishlist(item)" v-else><i class="far fa-heart mr-0" /></b-button>

        <p class="font-size-16 font-weight-bold float-right">${{ item.price }}</p>
      </div>
    </b-card-body>
  </div>
</template>
<script>

export default {
  name: 'ProductGrid',
  props: {
    item: { type: Object },
    selectedCart: { type: Boolean },
    selectedWishCart: { type: Boolean }
  },
  methods: {
  }
}
</script>
